import React from 'react'
import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import { Box, Typography, Grid, StepLabel, StepContent } from '@mui/material'
import { TextComponent, TextField } from '@front/squirtle'

const Row1 = ({ price, driver_estimation, isManagingRecord, estimatedDelay, duration, estimation_type = 'none', isManualPriceRequired, schedule_type }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={estimation_type !== 'special'}>
      <TextField
        label={I18n.t(`price.${estimation_type}`)}
        name='manual_price'
        InputProps={{
          endAdornment: <Typography sx={{ ml: 1, mb: 1 }}> {I18n.t('fiat.eur.sign')} </Typography>,
          inputProps: { style: { textAlign: 'right' } }
        }}
        nextField='driver_comment'
        alwaysInclude={isManualPriceRequired}
        required={isManualPriceRequired}
      />
    </Grid>
    <Grid item xs hidden={estimation_type === 'special'}>
      <TextField
        label={I18n.t(`price.${estimation_type}`)}
        value={price || '-'}
        disabled
      />
    </Grid>
    <Grid item xs hidden={!isManagingRecord}>
      <TextComponent
        label={I18n.t(`price.driver.${estimation_type}`)}
        value={driver_estimation}
        disabled
      />
    </Grid>
    <Grid item xs hidden={schedule_type === 'planned'}>
      <TextComponent
        label={I18n.t('transport.estimate_delay')}
        value={estimatedDelay || '-'}
        disabled
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('transport.estimate_duration')}
        value={duration || '-'}
        InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
        disabled
      />
    </Grid>
  </Grid>

const Row2 = ({ pickup, distance, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <TextComponent
        label={I18n.t('transport.pickUp.user')}
        value={FormatUtils.formatDateBackToFront(pickup, '[Le] DD/MM/YYYY [à] HH[h]mm[min]')}
        disabled
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('distance.label', { count: 1 })}
        value={distance || 0}
        disabled
      />
    </Grid>
  </Grid>

const Row3 = ({ margin }) =>
  <Grid item xs={12} hidden={!margin}>
    <TextComponent
      label={I18n.t('traffic_alert.label', { count: 1 })}
      value={margin}
      disabled
    />
  </Grid>


const StepEstimationSection = props => <>
  <StepLabel>
    <Typography variant='h5' >{I18n.t('quotation.estimation')}</Typography>
  </StepLabel>
  <StepContent>
    <Grid container spacing={1} rowSpacing={1.5}>
      <Row1 {...props} />
      <Row2 {...props} />
      <Row3 {...props} />
    </Grid>
  </StepContent>
</>

const EstimationSection = props =>
  <Box sx={{ p: 1, borderRadius: 1, boxShadow: 6 }}>
    <Typography variant='h5' >{I18n.t('quotation.estimation')}</Typography>
    <Box sx={{ p: 1 }}>
      <Grid container rowSpacing={1.5}>
        <Row1 {...props} />
        <Row2 {...props} />
        <Row3 {...props} />
      </Grid>
    </Box>
  </Box>

const EstimationSectionManager = ({ isStep = false, ...props }) => !!isStep ? <StepEstimationSection {...props} /> : <EstimationSection {...props} />

export default React.memo(EstimationSectionManager)
